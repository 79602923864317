.nft-card {
  $parentBlock: &;
  @apply relative;

  &__card {
    @apply relative block overflow-hidden rounded-3xl border px-2 pt-2 pb-3 transition-colors bg-black-400 border-black-400;

    &:hover, &:focus {
      @apply border-black-300;

      #{$parentBlock}__button {
        @apply opacity-100;
      }
    }
  }

  &__media {
    @apply mb-3 aspect-square w-full overflow-hidden rounded-3xl text-transparent bg-black-500;

    width: 100% !important;
    height: 100% !important;

    @screen md {
      @apply mb-4;
    }

    &-wrapper {
      position: relative;
    }
  }

  &__name {
    @apply mx-3 mb-3 block truncate font-bold text-white text-s;

    @screen md {
      @apply mx-4 mb-4 text-m;
    }
  }

  &__price {
    @apply block rounded-xl border px-3 py-2 font-bold text-white border-black-300 text-s;

    @screen md {
      @apply rounded-2xl px-5;
    }

    &--not-listed {
      @apply text-black-200;

      @screen md {
        @apply py-4;
      }
    }
  }

  &__price-label {
    @apply block text-xs text-black-200;
  }

  &__button {
    @apply absolute right-1/2 bottom-8 block translate-x-1/2 translate-y-1/2 opacity-0;
  }
}
