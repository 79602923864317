.showcase-description {
  &__title {
    @apply mb-2 text-center text-title-xs font-bold uppercase text-accent-500;

    @screen md {
       @apply mb-3 text-title-s;
    }
  }

  &__description {
    @apply mb-12 text-center text-title-m font-bold;

    @screen md {
       @apply mb-20 text-title-xl;
    }
  }
}
