.banner-main {
  grid-area: banner-main;

  @apply px-8 py-14 bg-black-500 text-center;

  @screen md {
    @apply pl-12 py-30 text-left;
  }

  @screen xl {
    @apply pl-24 py-52;
  }

  &__title {
    @apply text-white text-title-s font-bold;

    @screen md {
      @apply text-title-m;
    }

    @screen xl {
      @apply text-title-xl;
    }
  }

  &__description {
    @apply text-white text-title-xxs font-normal mt-4 mb-8;

    @screen md {
      @apply mt-8 mb-16;
    }
  }

  &__button {
    @apply bg-gold border-gold hover:bg-white text-black-500 overflow-hidden rounded-full justify-between;
  }
}
