.club-banner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "banner-main"
    "banner-video";

  @apply mx-auto pb-24 border-b border-b-accent-500;

  @screen md {
    @apply gap-2 pb-0;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "banner-main  ."
      "banner-main  banner-video"
      "banner-main  .";
  }

  @screen lg {
    @apply gap-8;
  }

  @screen xl {
    @apply gap-16;
  }
}
