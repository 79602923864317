.description {
  @apply text-white bg-black-500 border border-solid border-accent-500 p-8 my-12 rounded-xl;

  @screen md {
    @apply p-12;
  }

  &__title {
    @apply pb-4 text-title-l font-bold;
  }

  &__text {
    @apply text-title-xxs;
  }
}