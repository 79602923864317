.banner-main {
  grid-area: banner-main;

  @apply px-8 py-14 bg-gradient-to-b from-gradient-euro2024Init to-gradient-euro2024End text-center;

  @screen md {
    @apply pl-12 py-30 bg-gradient-to-r text-left;
  }

  @screen xl {
    @apply pl-24 py-52;
  }

  &__title {
    @apply text-white text-title-s font-bold;

    @screen md {
      @apply text-title-m;
    }

    @screen xl {
      @apply text-title-xl;
    }
  }

  &__description {
    @apply text-white text-title-xxs font-normal mt-4 mb-8;

    @screen md {
      @apply mt-8 mb-16;
    }
  }
}
