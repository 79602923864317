.banner-video {
  grid-area: banner-video;

  @apply mx-auto pt-16 container;

  div {
    height: unset !important;
  }

  @screen md {
    @apply py-0 pl-0 pr-10;
  }

  &__media {
    @apply border border-black-300 rounded-4xl max-w-2xl;
  }
}
