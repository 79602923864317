.banner-image {
  grid-area: banner-image;

  @apply h-[421px] bg-black-500 bg-[url('/paris2024/banner-desktop.svg')] bg-cover;

  @screen md {
    @apply h-[600px];
  }

  @screen lg {
    @apply h-[700px];
  }

  @screen xl {
    @apply h-[800px];
  }
}
