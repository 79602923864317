.link-button {
  $root: &;
  @apply inline-block cursor-pointer rounded-full font-bold uppercase leading-none transition-all
  bg-black-500 text-accent-500 text-m;

  &__primary {
    @apply border border-accent-500;

    &:hover, &:focus {
      @apply bg-accent-500 text-black-500 overflow-hidden rounded-full;
      background-color: #DAFF02;
      color: #101010;
    }
  }

  &__secondary {
    @apply border border-black-400;

    &:hover, &:focus {
      @apply border-accent-500;
    }

    &:is(#{$root}--loading) {
      @apply border-none;
    }
  }

  &--loading {
    @apply flex justify-start w-max gap-2 items-center text-black-200 border-black-200;

    svg.loader {
      @apply w-4 animate-spin;
    }

    svg:not(.loader) {
      @apply hidden;
    }
    &:hover, &:focus {
      @apply text-black-200 bg-black-400 border-black-200;
    }
  }

  &--disabled {
    @apply border-none text-black-300;

    &:hover, &focus {
      @apply border-none text-black-300 bg-black-400 cursor-not-allowed;
    }
  }

  &--large {
    @apply px-8 py-5;
  }

  &--medium {
    @apply px-6 py-3.5;
  }

  &--small {
    @apply px-4 py-2 text-s;
  }

  &__icon {
    @apply ml-6 inline-block w-5 align-text-bottom;
  }
}
