.paris2024-banner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "banner-main"
    "banner-image";

  @apply border-b border-b-accent-500;

  @screen md {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "banner-main  banner-image";
  }
}
