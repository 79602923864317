.banner-image {
  grid-area: banner-image;

  @apply h-[421px] bg-[#143CDB] bg-[url('/euro2024/banner-mobile.svg')] bg-cover;

  @screen md {
    @apply h-[600px] bg-[#143CDB] bg-[url('/euro2024/banner-desktop.svg')];
  }

  @screen lg {
    @apply h-[700px];
  }

  @screen xl {
    @apply h-[800px];
  }
}
