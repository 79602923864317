.vault-showcase {
  &__slide {
    @apply py-28 border-b border-accent-500;

    @screen md {
      @apply py-40;
    }
  }

  &__container {
    @apply container mx-auto;
  }
}
