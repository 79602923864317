.athlete-card {
  &__card {
    @apply relative w-32 h-auto shrink-0 overflow-hidden;

    @screen md {
      @apply w-64 h-auto;
    }
  }

  &__gradient {
    @apply absolute inset-0 bg-gradient-to-t from-black-500 via-transparent to-transparent;
  }

  &__image {
    @apply w-full h-auto rounded-2xl border border-black-200;
  }

  &__data {
    @apply absolute bottom-0 left-0 right-0 m-2;

    @screen md {
      @apply mb-11 ml-4;
    }

    &__country {
      @apply hidden;

      @screen md {
        @apply flex flex-row gap-2 align-middle ;
      }

      &__image {
        @apply h-3;
      }

      &__text {
        @apply font-bold text-xxs text-white uppercase mb-2;
      }
    }
  
    &__name {
      @apply text-xs text-white;

      @screen md {
        @apply font-bold text-title-xxs text-white mb-1;
      }
    }
  
    &__sport {
      @apply hidden;

      @screen md {
        @apply block font-bold text-xs text-white uppercase;
      }
    }
  }
}
