.home-footer {
  &__container {
    @apply mx-auto container py-20 grid;

    grid-template-areas:
      "polygon"
      "thirdweb"
      "chiliz";

    @screen xl {
      grid-template-areas:
        "polygon  thirdweb  chiliz";
    }
  }

  &__image-polygon {
    @apply mx-auto py-2;

    grid-area: "polygon";
  }

  &__image-thirdweb {
    @apply mx-auto py-2;

    grid-area: "thirdweb";
  }

  &__image-chiliz {
    @apply mx-auto py-2;

    grid-area: "chiliz";
  }
}
