.federation-showcase {
  &__slide {
    @apply border-b py-28 border-accent-500;

    @screen md {
      @apply py-40;
    }
  }

  &__title {
    @apply mb-2 text-center text-title-xs font-bold uppercase text-accent-500;

    @screen md {
       @apply mb-3 text-title-s;
    }
  }

  &__description {
    @apply mb-12 text-center text-title-m font-bold;

    @screen md {
       @apply mb-20 text-title-xl;
    }
  }

  &__carousel {
    @apply mb-16 flex h-36 w-fit gap-4;

    animation: carousel 30s linear infinite;

    @keyframes carousel {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-50%);
      }
    }

    @screen md {
      @apply mb-20 h-96 gap-8;
    }
  }

  &__explain {
    @apply container mx-auto text-center text-m;
  }

  &__explain-link {
    @apply block;
  }
}
