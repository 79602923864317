.home-header {
  &__slide {
    @apply w-full pt-24 pb-3 bg-accent-500;

    @screen md {
      @apply pt-28 pb-8;
    }

    @screen lg {
      @apply h-screen;
    }
  }

  &__container {
    @apply container mx-auto px-0;

    @screen sm {
      @apply px-8;
    }

    @screen md {
      @apply px-10;
    }

    @screen lg {
      @apply h-full px-14;
    }

    @screen xl {
      @apply px-16;
    }

    @screen 2xl {
      @apply px-20;
    }
  }

  &__card {
    @apply relative rounded-3xl px-5 pt-5 pb-8 bg-black-500;

    @screen md {
      @apply px-8 pt-8;
    }

    @screen lg {
      @apply grid h-full gap-x-8 pl-16;
      grid-template-areas:
        "title  media"
        "button media";
      grid-template-columns: 1fr 1fr;
    }
  }

  &__title {
    @apply mb-7 text-center text-title-l font-bold;

    @screen lg {
      @apply mb-10 self-end justify-self-start text-left text-title-l;
      grid-area: title;
    }

    @screen xl {
      @apply text-title-xl;
    }

    @screen 2xl {
      @apply text-title-xxl;
    }
  }

  &__subtitle {
    @apply mb-7 text-center text-s font-normal;

    @screen lg {
      @apply mb-10 text-left text-title-xs;
    }

    @screen xl {
      @apply text-title-s;
    }

    @screen 2xl {
      @apply text-title-m;
    }
  }

  &__media {
    @apply mb-7 overflow-hidden rounded-4xl;

    div {
      height: unset !important;
    }

    @screen lg {
      @apply relative mb-0;
      grid-area: media;

      > *, video {
        @apply inset-0 object-center;
        position: absolute !important;
        object-fit: cover !important;
      }
    }
  }

  &__button {
    @apply flex items-center justify-between font-bold text-m;

    @screen lg {
      @apply self-start justify-self-start px-11 py-5 font-bold text-title-xs;
      grid-area: button;
    }
  }

  &__side-texts {
    @apply pointer-events-none flex justify-between mb-13;

    @screen md {
      @apply absolute inset-0 mb-0 grid;
      grid-template-areas:
        "top-left-text     top-right-text"
        "center-left-text  center-right-text"
        "bottom-left-text  bottom-right-text";
    }
  }

  &__top-left-text {
    @apply hidden;

    @screen md {
      @apply block -translate-x-full rotate-180 self-start justify-self-start px-4 text-right text-black-500 text-xxs;
      grid-area: top-left-text;
      writing-mode: vertical-rl;
    }
  }

  &__center-left-text {
    @apply text-black-200 text-xxs;

    @screen md {
      @apply block -translate-x-full rotate-180 self-center justify-self-start px-4 text-center text-black-500;
      grid-area: center-left-text;
      writing-mode: vertical-rl;
    }
  }

  &__bottom-left-text {
    @apply text-right text-black-200 text-xxs;

    @screen md {
      @apply block -translate-x-full rotate-180 self-end justify-self-start px-4 text-left text-black-500;
      grid-area: bottom-left-text;
      writing-mode: vertical-rl;
    }
  }

  &__top-right-text {
    @apply hidden;

    @screen md {
      @apply block translate-x-full self-start justify-self-end px-4 text-black-500 text-xxs;
      grid-area: top-right-text;
      writing-mode: vertical-rl;
    }
  }

  &__center-right-text {
    @apply hidden;

    @screen md {
      @apply block translate-x-full self-center justify-self-end px-4 text-center text-black-500 text-xxs;
      grid-area: center-right-text;
      writing-mode: vertical-rl;
    }
  }

  &__bottom-right-text {
    @apply hidden;

    @screen md {
      @apply block translate-x-full self-end justify-self-end px-4 text-right text-black-500 text-xxs;
      grid-area: bottom-right-text;
      writing-mode: vertical-rl;
    }
  }
}
