.nft-card-badge {
  $parent: &;
  @apply absolute bg-black-400 text-white flex gap-2 items-center text-xxs;
  padding: 6px 16px;
  z-index: 2;
  top: 6px;

  &--fragment {
    @apply border-r-2 border-r-accent-500;
    border-radius: 20px 0 12px;

    &#{$parent}--card {
      left: 8px;
    }

    &#{$parent}--media {
      top: 0;
      left: 0;
    }
  }

  &--vault {
    @apply border-b-2 border-b-accent-500;
    border-radius: 0px 0px 12px 12px;
    left: calc(50% - 47.5px);

    &#{$parent}--media {
      top: 0;
    }
  }

  @media screen and (max-width: 768px) {
    &--card {
      @apply border-r-2 border-r-accent-500;
      left: 8px;
      border-radius: 20px 0 12px;

      #{$parent} {
        &__text {
          @apply hidden;
        }
      }
    }
  }
}