.nft-collection {
  &__container {
    @apply container p-0 mx-auto grid grid-cols-2 gap-x-4 gap-y-5 whitespace-nowrap mb-30;

    @screen lg {
      @apply grid-cols-4;
    }
  }
}

.nft-card {
  $parentBlock: &;

  &__card {
    @apply block rounded-3xl border px-2 pt-2 pb-3 transition-colors bg-black-400 border-black-400;

  }

  &__media {
    @apply animate-pulse block mb-3 aspect-square w-11/12 rounded-3xl bg-gradient-to-r from-gradient-init to-gradient-end;
  }

  &__name {
    @apply animate-pulse mx-3 mb-3 block h-5 w-3/4 rounded-3xl bg-gradient-to-r from-gradient-init to-gradient-end;

    &--short {
      @apply mx-3 mb-3 block h-5 w-2/4 rounded-3xl bg-gradient-to-r from-gradient-init to-gradient-end;
    }
  }

  &__price {
    @apply animate-pulse block rounded-3xl px-3 py-2 h-14 border border-black-300 text-s;
  }
}
