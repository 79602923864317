.organizer-card {
  &__card {
    @apply aspect-square bg-accent-500 h-full shrink-0 overflow-hidden rounded-4xl bg-black-400;
    box-shadow: 0px 4px 40px rgba(218, 255, 2, .8);

    @screen md {
      @apply aspect-auto h-full w-64;
      box-shadow: 0px 4px 80px rgba(218, 255, 2);
    }
  }

  &__image {
    @apply h-full w-full object-cover object-center bg-black-500 p-6;

    @screen md {
      @apply mb-5 aspect-square h-auto rounded-4xl p-16;
     box-shadow: 0px 0px 24px rgba(163, 165, 168, 0.5);

    }
  }

  &__acronym {
    @apply hidden;

    @screen md {
      @apply mx-8 mb-2 block truncate font-bold text-m;
    }
  }

  &__name {
    @apply hidden;

    @screen md {
      @apply mx-8 block text-s;
    }
  }
}
