.series-card {
  $parentBlock: &;

  &__card {
    @apply relative mb-5 block overflow-hidden rounded-2xl bg-white transition-shadow text-black-500;

    @screen md {
      @apply rounded-4xl;
    }

    &:hover, &:focus {
      @apply shadow-lg shadow-accent-500/20 text-black-500;

      #{$parentBlock}__image > img {
        @apply scale-105;
      }
    }
  }

  &__header {
    @apply grid items-center p-8 gap-x-4;
    grid-template-columns: 80px auto auto;
    grid-template-areas:
    "avatar title arrow"
    "avatar subtitle arrow"
    "description description description";
  }

  &__avatar {
    @apply flex;
    max-width: 64px;
    grid-area: avatar;

    img {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &__organizer-acronym {
    @apply block truncate  font-bold text-m;
    grid-area: title;
  }

  &__series-name {
    @apply block truncate text-m pt-2;
    grid-area: description;
    @screen md {
      @apply text-title-xs pt-4;
    }
  }

  &__organizer-name {
    @apply block truncate text-s text-black-300;
    grid-area: subtitle;
  }

  &__image {
    @apply relative block h-52 overflow-hidden rounded-t-2xl;

    @screen md {
      @apply rounded-t-4xl;
    }

    @screen lg {
      @apply h-[28rem];
    }

    > img {
      @apply inset-0 h-full w-full transition-transform;
      width: 100% !important;
      height: 100% !important;
      position: absolute !important;
      object-fit: cover !important;
    }
  }

  &__icon {
    @apply w-5 justify-self-end;
    grid-area: arrow;
  }
}
